
















































































































































import { Component, Prop } from "vue-property-decorator";
import {
  Application,
  ApplicationPaginator,
  ApplicationsQueryVariables,
  ApplicationStatusEnum,
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables,
  Maybe,
  QueryApplicationsOrderByColumn,
  QueryApplicationsWhereColumn,
  SortOrder,
  SqlOperator,
  UpdateApplicationInput,
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables,
} from "@/generated/graphql";
import {
  Applications,
  DeleteApplication,
  UpdateApplication,
} from "@/graphql/queries/Applications.graphql";
import { DocumentNode } from "graphql";
import ListView from "@/core/UI/Views/ListView";
import UserApplicationsTable from "@components/parts/tables/Applications.vue";
import { ApplicationStatuses } from "@/core/static/dict";
import { getMainUserEmail } from "@/domain/user";

@Component({
  components: {
    UserApplicationsTable,
  },
})
export default class UserApplications extends ListView {
  @Prop({ type: Array, default: () => [] }) items!: Array<Application>;
  protected readonly fetchListQuery: DocumentNode = Applications;
  protected readonly fetchKey: string = "applications";
  public readonly ApplicationStatuses = ApplicationStatuses;
  public readonly ApplicationStatusEnum = ApplicationStatusEnum;

  private applications: ApplicationPaginator = {
    paginatorInfo: {
      count: 0,
      total: 0,
      currentPage: 1,
      perPage: 5,
      lastPage: 0,
      firstItem: 5,
      hasMorePages: true,
    },
    data: [],
  };

  public loading: boolean = false;
  public done: boolean = false;
  public error: boolean = false;
  public openForm: boolean = false;
  public selectedApplication: Application | null = null;
  public deleteApplicationId: string = "";

  public onClickItem(application: Application): void {
    this.selectedApplication = application;
    this.applicationFormData.id = application.id;
    this.applicationFormData.status = application.status;
    this.applicationFormData.answer = application.answer;
    this.openForm = true;
  }

  public get selectedApplicationUserName(): string | undefined {
    return (
      this.selectedApplication?.name ||
      `${this.selectedApplication?.user?.family_name} ${this.selectedApplication?.user?.first_name}`
    );
  }

  public get selectedApplicationUserPhone(): string | undefined {
    if (this.selectedApplication?.phone) {
      return this.selectedApplication?.phone;
    }

    if (this.selectedApplication?.user?.phones?.length) {
      return (
        this.selectedApplication?.user.phones.find((item) => item.is_main)
          ?.value || ""
      );
    }

    return "";
  }

  public get selectedApplicationEmail(): string | undefined | null {
    if (this.selectedApplication?.email) {
      return this.selectedApplication.email;
    }

    if (this.selectedApplication?.user?.emails?.length) {
      return getMainUserEmail(this.selectedApplication.user.emails);
    }

    return "";
  }

  public get selectedApplicationMessage(): string {
    return this.selectedApplication?.message || "";
  }

  public get selectedApplicationTitle(): string {
    return this.selectedApplication?.title || "";
  }

  public get isApproved(): boolean {
    return this.selectedApplication?.status === ApplicationStatusEnum.Approved;
  }

  public get isRejected(): boolean {
    return this.selectedApplication?.status === ApplicationStatusEnum.Rejected;
  }

  public get isProcessing(): boolean {
    return (
      this.selectedApplication?.status === ApplicationStatusEnum.Processing
    );
  }

  public applicationFormData: UpdateApplicationInput = {
    id: "0",
    answer: null,
    status: null,
  };

  public async sendAnswer(status: Maybe<ApplicationStatusEnum>): Promise<void> {
    try {
      this.applicationFormData.status = status;
      this.loading = true;
      await this.$apollo.mutate<
        UpdateApplicationMutation,
        UpdateApplicationMutationVariables
      >({
        mutation: UpdateApplication,
        variables: { input: this.applicationFormData },
      });
      this.done = true;
      this.openForm = false;
      await this.refetch();
    } catch (error) {
      this.error = true;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  public removeApplicationId: string | null = "";

  public async deleteApplication(): Promise<void> {
    if (!this.removeApplicationId) return;
    try {
      this.loading = true;
      await this.$apollo.mutate<
        DeleteApplicationMutation,
        DeleteApplicationMutationVariables
      >({
        mutation: DeleteApplication,
        variables: { id: this.removeApplicationId },
      });
      this.removeApplicationId = null;
      await this.refetch();
    } catch (error) {
      this.error = true;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  private get itemsData() {
    return this.list?.data ?? [];
  }

  public selectedStatus = "PROCESSING";

  protected get fetchListVariables(): ApplicationsQueryVariables {
    return {
      where: {
        column: QueryApplicationsWhereColumn.Status,
        operator: SqlOperator.Eq,
        value: this.selectedStatus.toLowerCase(),
      },
      orderBy: [
        {
          column: QueryApplicationsOrderByColumn.CreatedAt,
          order: SortOrder.Desc,
        },
      ],
    };
  }
}
