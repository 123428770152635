


















































































import { Component } from "vue-property-decorator";
import { BaseView } from "@/core/UI/Views/BaseView";
import { Routes } from "@/types/core";
import ApplicationsTable from "@widgets/admin/Applications.vue";

@Component({
  name: "AdminPage",
  components: {
    ApplicationsTable,
  },
})
export default class AdminPage extends BaseView {
  private readonly Routes: typeof Routes = Routes;
  created() {
    if (!this.VIEW_ADMIN_DASHBOARD)
      this.$router.push({ name: Routes.noRights });
  }
  get VIEW_ADMIN_DASHBOARD(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_ADMIN_DASHBOARD");
  }
}
